import { useSearchParams } from 'react-router-dom'

export const GET_ALL_QA_REPORTS_KEY = 'GET_ALL_QA_REPORTS'

export function useMakeGetAllQAReportsKey() {
  const [searchParams] = useSearchParams()

  const action = searchParams.get('action') ?? undefined
  const initialValue = searchParams.get('initialValue') ?? undefined
  const type = searchParams.getAll('type')
  const userId = searchParams.getAll('userId').map(Number) ?? undefined
  const qaResponsibleId =
    searchParams.getAll('qaResponsibleId').map(Number) ?? undefined
  const clientName = searchParams.getAll('clientName') ?? undefined

  const params = {
    action,
    initialValue,
    type,
    userId,
    qaResponsibleId,
    clientName
  }

  return {
    queryKey: [GET_ALL_QA_REPORTS_KEY, params],
    params
  }
}
