import { Avatar, Button, DeadlineTag, Scrollarea } from '@/components'
import { twMerge } from '@/styles/tailwindMergeConfig'

import { ScheduleAnalysisDialog } from './ScheduleAnalysisDialog'
import { DownloadReport } from './DownloadReport'
import { QAStatus } from './QAStatus'

import { Link } from 'react-router-dom'
import { MdLaunch } from 'react-icons/md'

import { useUserInfos } from '@/hooks/useUserInfos'
import { QAReport } from '@/types/QAReport'
import { AddResponsiblefromQAMenu } from './AddResponsiblefromQAMenu'

import * as Tooltip from '@radix-ui/react-tooltip'

import { MdLink } from 'react-icons/md'
import { useCopy } from '@/hooks/useCopy'

export function Reports({ reports }: { reports: QAReport[] }) {
  const { findUserInfo } = useUserInfos()
  const { copy } = useCopy()

  function copyInfo(info: string, hover: string, content: string) {
    return (
      <span className="flex max-h-10 w-[160px] gap-x-1 break-words">
        {info && <span className="w-20 truncate">{info}</span>}
        <Tooltip.Provider delayDuration={0} skipDelayDuration={0}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild type="button">
              <button
                aria-label="Copiar nome da demanda"
                className="flex w-auto items-center gap-[6px] hover:opacity-80"
                onClick={() => copy(content ? content : info)}
              >
                <MdLink
                  size={12}
                  className="text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
                />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="ml-2" align="center" side="right">
                <div className="mr-3 flex items-center gap-2 truncate rounded-sm bg-cromai-m3-sys-light-surface-variant px-3 text-labelSmall text-cromai-m3-sys-light-on-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
                  {hover}
                </div>
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </span>
    )
  }

  return (
    <section className="w-full">
      <div className="mb-3 flex w-full justify-between gap-2 px-4 font-mono text-labelLarge text-cromai-m3-sys-light-tertiary dark:text-cromai-m3-sys-dark-tertiary">
        <span className="w-[100px]">ID Demanda</span>
        <span className="w-[100px] text-center">Revisor(a)</span>
        <span className="w-[100px] text-center">Cliente</span>
        <span className="w-[160px]">Demanda</span>
        <span className="w-[56px] text-center">Prazo</span>
        <span className="w-[200px] text-center">Agendamento</span>
        <span className="w-[100px] text-center">Responsável QA</span>
        <span className="w-[60px] text-center">RO</span>
        <span className="w-[60px] text-center">Relatório</span>
        <span className="w-[60px] text-center">Status QA</span>
      </div>
      <Scrollarea className="flex h-[calc(100vh-7.3rem)] flex-col overflow-hidden">
        {reports.map((report, index) => {
          const reviewer = findUserInfo(report.reviewerId)
          const responsible = findUserInfo(report.responsibleId)

          return (
            <div
              key={report.id}
              data-testid={
                report.isConcluded ? 'box-conclued' : 'box-not-conclued'
              }
              className={twMerge(
                'mb-2 flex h-14 w-full items-center justify-between gap-2 rounded p-4',
                report.isConcluded
                  ? twMerge(
                      'text-bodyMedium',
                      'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface',
                      'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface'
                    )
                  : twMerge(
                      'text-labelLarge',
                      'bg-cromai-m3-sys-light-surface2 dark:bg-[#1E261E]',
                      'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface'
                    )
              )}
            >
              <span className="w-[100px]">
                {copyInfo(
                  !report.demandId ? '-------' : report.demandId,
                  'Copiar id da demanda',
                  ''
                )}
              </span>
              <div className="flex w-[100px] justify-center">
                {reviewer && (
                  <Avatar
                    size="small"
                    name={reviewer!.name}
                    url={reviewer.image}
                  />
                )}
              </div>
              <span className="w-[100px] truncate">{report.clientName}</span>
              {copyInfo(report.demandName, 'Copiar nome da demanda', '')}
              <span className="w-[56px]">
                <DeadlineTag
                  date={report.deliveryDate.date}
                  type={report.deliveryDate.type}
                />
              </span>
              <ScheduleAnalysisDialog
                fullDate={report.deliveryDate.fullDate}
                scheduleDate={report.scheduleDate}
                reportId={report.id}
              />

              <div className="flex w-[100px] justify-center">
                <AddResponsiblefromQAMenu
                  reportId={report.id}
                  reportIndex={index}
                  responsible={responsible}
                />
              </div>
              <span className="flex w-[60px] items-center justify-center">
                <Link to={report.roLink} target="_blank" rel="noreferrer">
                  <Button size="icon" variant="outlined">
                    <MdLaunch size={12} />
                  </Button>
                </Link>
              </span>
              <div className="flex w-[65px] items-center justify-between gap-2">
                {report.qaReport ? (
                  <>
                    <DownloadReport reportId={report.id} qaReport={report.qaReport} />
                    {copyInfo('', 'Copiar link do relatório', report.qaReportLink)}
                  </>
                ) : (
                  'Processando...'
                )}
              </div>
              <QAStatus
                isConcluded={report.isConcluded}
                reportId={report.id}
                reportIndex={index}
              />
            </div>
          )
        })}
      </Scrollarea>
    </section>
  )
}
