import { apiAthenas } from '@/services/api/apiAthenas'
import { QAReport } from '@/types/QAReport'

export type QAReports = {
  initialValue: number
  finalValue: number
  totalQA: number
  requests: QAReport[]
}

type GetAllQAReports = {
  signal?: AbortSignal
  action?: string
  initialValue?: string
  type?: string[]
  userId?: number[]
  qaResponsibleId?: number[]
  clientName?: string[]
}

export async function getAllQAReports({ signal, ...props }: GetAllQAReports) {
  const params = new URLSearchParams()

  props.action && params.set('action', props.action)

  props.initialValue &&
    params.set('initialValue', props.initialValue?.toString())

  props.type?.forEach((type) => params.append('type', type))

  props.userId?.forEach((userId) => params.append('userId', userId.toString()))
  props.qaResponsibleId?.forEach((qaResponsibleId) =>
    params.append('qaResponsibleId', qaResponsibleId.toString())
  )
  props.clientName?.forEach((clientName) =>
    params.append('clientName', clientName)
  )

  const { data } = await apiAthenas.get<QAReports>('/qa', {
    signal,
    params
  })

  return data
}
