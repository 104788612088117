import { QAFilters, FilterDisplay } from '@/components'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'

type NavbarProps = {
  initialValue: number
  finalValue: number
  totalQA: number
}
export function Navbar({ initialValue, finalValue, totalQA }: NavbarProps) {
  const [_, setSearchParams] = useSearchParams()

  const isFirstPage = initialValue === 1
  const isLastPage = finalValue === totalQA

  function handleChangePage(action: 'previous' | 'next', value: number) {
    setSearchParams((prev) => {
      prev.delete('action')
      prev.delete('initialValue')

      prev.append('action', action)
      prev.append('initialValue', value.toString())

      return prev
    })
  }
  return (
    <div className="mb-3 flex w-full items-center gap-4">
      <h1 className="min-w-[200px] text-headlineSmall text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
        Solicitações de QA
      </h1>
      <QAFilters options={{ status: true, type: true }} />
      <FilterDisplay />
      <div
        className={twMerge(
          'ml-auto flex shrink-0 items-center gap-3',
          'text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background'
        )}
      >
        <span className="shrink-0">
          {`${initialValue} - ${finalValue} de ${totalQA}`}
        </span>
        <button
          title="Voltar"
          disabled={isFirstPage}
          onClick={() => handleChangePage('previous', initialValue)}
          className={twMerge(
            'flex size-12 items-center justify-center rounded disabled:pointer-events-none disabled:opacity-50',
            'hover:bg-cromai-m3-sys-light-surface-variant hover:dark:bg-cromai-m3-sys-dark-surface-variant',
            'hover:opacity-70'
          )}
        >
          <MdKeyboardArrowLeft size={24} />
        </button>
        <button
          title="Avançar"
          disabled={isLastPage}
          onClick={() => handleChangePage('next', finalValue)}
          className={twMerge(
            'flex size-12 items-center justify-center rounded disabled:pointer-events-none disabled:opacity-50',
            'hover:bg-cromai-m3-sys-light-surface-variant hover:dark:bg-cromai-m3-sys-dark-surface-variant',
            'hover:opacity-70'
          )}
        >
          <MdKeyboardArrowRight size={24} />
        </button>
      </div>
    </div>
  )
}
