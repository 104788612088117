import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button, Dialog, MultiSelect, SlideSheet } from '@/components'
import { MdFilterList } from 'react-icons/md'

import { DialogClose } from '@radix-ui/react-dialog'

import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { zodResolver } from '@hookform/resolvers/zod'
import { FilterSkeleton } from './FilterSkeleton'
import { useFiltersApplied } from '@/hooks/useFiltersApplied'
import { Tooltip } from '../Tooltip'

type FiltersProps = {
  options?: {
    status?: boolean
    type?: boolean
  }
}

const filtersSchema = z.object({
  type: z
    .array(z.enum(['scanweed-cane', 'scanweed-soybean', 'growline-cane']))
    .default([]),
  clientName: z.array(z.string()).default([]),
  qaResponsibleId: z.array(z.string()).default([]),
  userId: z.array(z.string()).default([])
})

type FiltersSchema = z.infer<typeof filtersSchema>

export function QAFilters({ options }: FiltersProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [_, setSearchParams] = useSearchParams()
  const { data: users, isLoading: isLoadingUsers } = useGetUsers()

  const { buttonLabel, filters } = useFiltersApplied()

  const { handleSubmit, control, reset } = useForm<FiltersSchema>({
    resolver: zodResolver(filtersSchema)
  })

  function handlerClearForm() {
    reset({
      type: [],
      clientName: [],
      qaResponsibleId: [],
      userId: []
    })
  }

  const onSubmit = handleSubmit((data, event) => {
    event?.preventDefault()

    setSearchParams((prev) => {
      prev.delete('type')
      prev.delete('clientName')
      prev.delete('qaResponsibleId')
      prev.delete('userId')

      data.type.forEach((type) => prev.append('type', type))

      data.qaResponsibleId.forEach((responsible) =>
        prev.append('qaResponsibleId', responsible)
      )

      data.userId.forEach((reviewer) => prev.append('userId', reviewer))

      data.clientName.forEach((companie) => prev.append('clientName', companie))

      return prev
    })
  })

  const responsibles = users?.map((responsible) => ({
    id: responsible.id.toString(),
    label: responsible.name
  }))

  const reviewers = users?.map((responsible) => ({
    id: responsible.id.toString(),
    label: responsible.name
  }))

  return (
    <>
      <SlideSheet
        heading="Filtrar"
        trigger={
          <div aria-label="btn-trigger-open-filters">
            <Tooltip
              align="center"
              side="bottom"
              listItems={filters}
              trigger={
                <div className="flex items-center gap-2 whitespace-nowrap text-cromai-m3-sys-light-on-background transition-all hover:opacity-80 dark:text-cromai-m3-sys-dark-on-background">
                  <MdFilterList size={14} />
                  {buttonLabel}
                </div>
              }
            />
          </div>
        }
      >
        {isLoadingUsers ? (
          <FilterSkeleton />
        ) : (
          <form
            onSubmit={onSubmit}
            className="flex h-[calc(100vh-6rem)] w-full flex-col gap-4 overflow-auto"
          >
            {options?.type && (
              <Controller
                name="type"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    placeholder="Tipo de demanda"
                    options={[
                      { id: 'scanweed-cane', label: 'Scanweed cana-de-açúcar' },
                      { id: 'scanweed-soybean', label: 'Scanweed soja' },
                      { id: 'growline-cane', label: 'Growline cana-de-açúcar' }
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
            <Controller
              name="qaResponsibleId"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Responsável"
                  options={responsibles!}
                  value={value}
                  onChange={onChange}
                  search
                />
              )}
            />
            <Controller
              name="userId"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Revisores"
                  options={reviewers!}
                  value={value}
                  onChange={onChange}
                  search
                />
              )}
            />
            <div className="mt-auto flex w-full">
              <Button
                type="button"
                onClick={handlerClearForm}
                variant="text"
                className="w-full"
              >
                Limpar
              </Button>
              <Button type="submit" className="w-full">
                Aplicar
              </Button>
            </div>
          </form>
        )}
      </SlideSheet>
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="h-[196px] w-[432px] p-6">
          <DialogClose asChild className="ml-auto mt-8">
            <Button>Fechar</Button>
          </DialogClose>
        </div>
      </Dialog>
    </>
  )
}
